import { SimpleSkeleton } from "shared";
import React, { ReactNode, Suspense } from "react";
import { CounterProps } from "types";

const counterMap = {
  elakkeensaajanasumistuki: React.lazy(() => import("./AeCounter")), //Miksei toimi?
  asumistuki: React.lazy(() => import("./AyCounter")),
  vanhempainraha: React.lazy(() => import("./PevaCounter")),
  peruspaivaraha: React.lazy(() => import("./RfCounters/PmCounter")),
  markkinatuki: React.lazy(() => import("./RfCounters/TmCounter")),
  sairauspaivarahakausi: React.lazy(() => import("./SjCounters/SpvkCounter")),
  sairauspaivaraha: React.lazy(() => import("./SjCounters/SjprCounter")),
  kuntoutusraha: React.lazy(() => import("./SjCounters/SjkbCounter")),
  erityishoitoraha: React.lazy(() => import("./SjCounters/SmCounter")),
  vanhempainpaivaraha: React.lazy(() => import("./SjCounters/VrCounter")),
  lastenhoidontuki: React.lazy(() => import("./WhCounters/WhChildcareCounter")),
  lastenhoidontukiaika: React.lazy(() => import("./WhCounters/WhCounter")),
  toimeentulotuki: React.lazy(() => import("./PcCounter")),
  opintotuki: React.lazy(() => import("./OpiskelijaCounters/OtCounter")),
  opintotukitulovalvonta: React.lazy(() => import("./OpiskelijaCounters/OtValvontaCounter")),
  arkipaivalaskuri: React.lazy(() => import("./ArkCounter")),
  koulumatkatukilaskuri: React.lazy(() => import("./OpiskelijaCounters/KmCounter")),
  lomakorvauksenlaskuri: React.lazy(() => import("./RfCounters/LkCounter")),
} as const;

type CounterName = keyof typeof counterMap;

export type CommonCounterProps = Omit<CounterProps, "apiUrl"> & {
  type: CounterName;
  loader?: ReactNode;
};

const CommonCounter: React.FC<CommonCounterProps> = (props) => {
  const { type, language, loader, debug } = props;

  const fallback = loader !== undefined ? loader : <SimpleSkeleton />;
  const Component = counterMap[type];

  return (
    <Suspense fallback={fallback}>
      <Component language={language} apiUrl={""} debug={debug} />
    </Suspense>
  );
};

export default CommonCounter;
