/* eslint-disable max-lines */
import axios from "axios";
import CommonCounter from "counters/CommonCounter";
import { LogoKelaWhite, LogoFpaWhite } from "kela-design-system/dist/logos";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
  Footer,
  Container,
  Link,
  LinkWithInfo,
  ListGroup,
  ListGroupItem,
  MainBar,
  MainBarAction,
  MainBarActionGroup,
  Page,
  MainBarContainer,
} from "kela-design-system";
import { IconCaretLeft, IconGlobe } from "kela-design-system/dist/icons/ui";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ErrorModal, SimpleSkeleton } from "shared";
import { Any, Language } from "types";
import { ErrorModalTestCounter } from "counters/ErrorModalTestCounter";
import getNavLink from "shared/functions/getNavLink";

type LangObject<T> = {
  fi: T;
  sv: T;
  en: T;
  [langCode: string]: T;
};

type CounterSettings = {
  language: Language;
  counter: string;
  urls: LangObject<string>;
  head: LangObject<{ title: string; description: string }>;
};

const TRANSLATIONS = {
  fi: {
    mainbar: {
      valitse: "Valitse kieli",
      kieli: "Suomi",
    },
    frontPage: {
      heading: "Laskurit",
    },
  },
  sv: {
    mainbar: {
      valitse: "Byt språk",
      kieli: "Svenska",
    },
    frontPage: {
      heading: "Räknaren",
    },
  },
  en: {
    mainbar: {
      valitse: "Choose language",
      kieli: "English",
    },
    frontPage: {
      heading: "Calculators",
    },
  },
} as const;

type GlobalState = {
  language: Language;
  loading: boolean;
  mainbar: {
    urls: LangObject<string>;
  };
  head: LangObject<{
    title: string;
    description: string;
  }>;

  translations: Translations;

  setLanguage: (val: Language) => void;
  setLoading: (val: boolean) => void;
  setMainbar: (val: { urls: LangObject<string> }) => void;
  setHead: (
    val: LangObject<{
      title: string;
      description: string;
    }>
  ) => void;
};

type Translations = (typeof TRANSLATIONS)[Language];

function App(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<Language>("fi");
  const [head, setHead] = useState<
    LangObject<{
      title: string;
      description: string;
    }>
  >({
    fi: {
      title: "Kela - Laskurit",
      description: "Kela - Laskurit",
    },
    sv: {
      title: "Fpa - räknar",
      description: "Fpa - räknar",
    },
    en: {
      title: "Kela - Calculators",
      description: "Kela - Calculators",
    },
  });
  const [mainbar, setMainbar] = useState<{ urls: LangObject<string> }>({
    urls: {
      fi: "?lang=fi",
      sv: "?lang=sv",
      en: "?lang=en",
    },
  });

  const state: GlobalState = {
    head,
    mainbar,
    loading,
    language,
    translations: TRANSLATIONS[language],
    setHead,
    setLanguage,
    setLoading,
    setMainbar,
  };

  const { title, description } = state.head[language];

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: language }}>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <style>
          {`html {
            scroll-behavior: smooth;
          }`}
        </style>
      </Helmet>
      <div style={{ margin: "-1px", position: "absolute", overflow: "hidden" }}>
        <Link
          href="#main-heading"
          children={
            state.language == "fi"
              ? "Siirry sisältöön"
              : state.language == "sv"
              ? "Gå till innehåll"
              : "Skip to content"
          }
        />
      </div>
      <Page
        util="kds-flex kds-flex-col"
        isOpen={false}
        sidebar={<div></div>}
        pageToolbar={<SimpleMainBar state={state} />}
        layout={
          <main>
            <Container>
              <div>
                <Routes>
                  <Route path="/:friendlyUrl" element={<CounterView state={state} />} />
                  <Route path="*" element={<FrontPage state={state} />} />
                  {process.env.NODE_ENV === "development" && (
                    <Route path="/errormodaltest" element={<ErrorModalTestCounter />} />
                  )}
                </Routes>
              </div>
            </Container>
          </main>
        }
        footer={
          <Footer
            links={() =>
              language === "fi" ? (
                <Link
                  href="https://www.kela.fi/anna-palautetta"
                  standalone
                  target="_blank"
                  newTabLabel="Avautuu uuteen välilehteen"
                >
                  Anna palautetta (kela.fi)
                </Link>
              ) : language === "sv" ? (
                <Link href="https://www.kela.fi/ge-respons" standalone target="_blank" newTabLabel="Öppna i en ny flik">
                  Ge respons (kela.fi)
                </Link>
              ) : (
                <Link
                  href="https://www.kela.fi/give-feedback"
                  standalone
                  target="_blank"
                  newTabLabel="Opens in a new tab"
                >
                  Help us improve our services (kela.fi)
                </Link>
              )
            }
          />
        }
      />
    </HelmetProvider>
  );
}

type StateProps = {
  state: GlobalState;
};

function FrontPage({ state }: StateProps) {
  const [params] = useSearchParams();
  const [settings, setSettings] = useState<Record<string, CounterSettings>>({});
  const navigate = useNavigate();

  const doNavigate = (event: React.MouseEvent, url: string) => {
    event.preventDefault();
    event.stopPropagation();
    navigate("/" + url);
  };

  useEffect(() => {
    const lang = params.get("lang");
    if (lang) {
      state.setLanguage(lang as Language);
    }
  }, [params]);

  useEffect(() => {
    const fetchSettings = async () => {
      state.setLoading(true);
      try {
        const response = await axios.get(`/api/settings`);
        setSettings(response.data);
      } catch (e) {
        console.error(e);
      }
      state.setLoading(false);
    };
    fetchSettings();
  }, []);

  const { translations } = state;

  if (state.loading) {
    return <SimpleSkeleton />;
  }
  return (
    <div>
      <h1 id="main-heading">{translations.frontPage.heading}</h1>
      <ListGroup as="div">
        {Object.keys(settings).map((url) => {
          if (settings[url].language !== state.language) return null;

          const head = settings[url].head[state.language];
          return (
            <ListGroupItem key={url}>
              <LinkWithInfo
                id={head.title}
                href={url}
                onClick={(event: Any) => doNavigate(event, url)}
                infoText={head.description}
              >
                {head.title}
              </LinkWithInfo>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
}

// eslint-disable-next-line complexity
function CounterView({ state }: StateProps) {
  const { friendlyUrl } = useParams();
  const [settings, setSettings] = useState<CounterSettings | Record<string, never>>({});

  useEffect(() => {
    if (!settings.urls) return;
    state.setLanguage(settings.language);
    state.setHead(settings.head);
    state.setMainbar({ urls: settings.urls });
  }, [friendlyUrl, settings]);

  useEffect(() => {
    if (!friendlyUrl) return;
    const fetchSettings = async () => {
      state.setLoading(true);
      try {
        const response = await axios.get(`/api/settings/${friendlyUrl}`);
        setSettings(response.data);
      } catch (e) {
        console.error(e);
      }
      state.setLoading(false);
    };
    fetchSettings();
  }, [friendlyUrl]);

  if (state.loading) {
    return <SimpleSkeleton />;
  }
  if (!settings.urls) {
    return <ErrorModal show language={state.language} unknownUrl />;
  }
  return (
    <>
      <nav>
        <p className="kds-mt-6">
          {settings.counter !== "arkipaivalaskuri" && (
            <Link href={getNavLink(settings.counter, state.language)}>
              <span>
                <IconCaretLeft />
              </span>
              {state.language == "fi"
                ? "Lue lisää etuudesta (kela.fi)"
                : state.language === "sv"
                ? "Läs mer om förmånen (fpa.fi)"
                : "Read more about the benefit (kela.fi)"}
            </Link>
          )}
        </p>

        <p className="mt-3">
          <Link
            href={
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000/"
                : state.language == "fi"
                ? "https://www.kela.fi/laskurit"
                : state.language == "sv"
                ? "https://www.kela.fi/berakningar"
                : "https://www.kela.fi/calculators"
            }
          >
            <span>
              <IconCaretLeft />
            </span>
            {state.language == "fi"
              ? "Laskurit (kela.fi)"
              : state.language == "sv"
              ? "Räknare (fpa.fi)"
              : "Calculators (kela.fi)"}
          </Link>
        </p>
      </nav>

      <CommonCounter type={settings.counter as Any} language={state.language} />
    </>
  );
}

const getLogo = (language: string) => {
  if (language == "sv") {
    return (
      <Link href="https://www.kela.fi/privatpersoner" aria-label="Tillbaka till Fpa-webbplatsen">
        <LogoFpaWhite height="48" width="83" />
      </Link>
    );
  } else if (language === "en") {
    return (
      <Link href="https://www.kela.fi/main-page" aria-label="Back to the Kela website">
        <LogoKelaWhite height="48" width="83" />
      </Link>
    );
  } else {
    return (
      <Link href="https://www.kela.fi/henkiloasiakkaat" aria-label="Takaisin Kela-sivustolle">
        <LogoKelaWhite height="48" width="83" />
      </Link>
    );
  }
};

const SimpleMainBar = ({ state }: { state: StateProps["state"] }) => {
  const [isLangOpen, setIsLangOpen] = useState(false);
  const navigate = useNavigate();
  const actionLabelClasses = "d-none d-sm-block";

  const doNavigate = (event: React.MouseEvent, url: string) => {
    event.preventDefault();
    event.stopPropagation();
    navigate("/" + url);
    setIsLangOpen(false);
  };

  const urls = state.mainbar.urls;
  const { language, translations } = state;
  const logoComponent = getLogo(language);

  return (
    <MainBarContainer>
      <Container maxWidth="2xl" gx={0} fluid>
        <MainBar stackOnMobile as="div" shadow={false}>
          <div>
            <div>{logoComponent}</div>
            <div style={{ color: "#ffffff" }}>{translations.frontPage.heading}</div>
          </div>
          <MainBarActionGroup>
            <Dropdown isOpen={isLangOpen} onClick={() => setIsLangOpen(!isLangOpen)}>
              <DropdownToggle
                as={MainBarAction}
                icon={<IconGlobe />}
                labelUtil={actionLabelClasses}
                aria-label={translations.mainbar.kieli}
              >
                {translations.mainbar.kieli}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownMenuItem heading>{translations.mainbar.valitse}</DropdownMenuItem>
                <DropdownMenuItem
                  href={urls?.fi}
                  active={language === "fi"}
                  onClick={(e: Any) => doNavigate(e, urls.fi)}
                >
                  Suomeksi
                </DropdownMenuItem>
                <DropdownMenuItem
                  href={urls?.sv}
                  active={language === "sv"}
                  onClick={(e: Any) => doNavigate(e, urls.sv)}
                >
                  På svenska
                </DropdownMenuItem>
                {urls.en && (
                  <DropdownMenuItem
                    href={urls?.en}
                    active={language === "en"}
                    onClick={(e: Any) => doNavigate(e, urls.en)}
                  >
                    In English
                  </DropdownMenuItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </MainBarActionGroup>
        </MainBar>
      </Container>
    </MainBarContainer>
  );
};

export default App;
