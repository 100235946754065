import { DatePicker, DatePickerRef, TextInput } from "kela-design-system";
import React, { ReactElement, useState, useEffect, useRef, MutableRefObject } from "react";
import { isValidDate } from "helpers";
import useId from "../hooks/useId";
import fi from "date-fns/locale/fi";
import sv from "date-fns/locale/sv";
import en from "date-fns/locale/en-US";
import trls from "../translations";

type TextInputProps = React.ComponentProps<typeof TextInput>;
interface DateInputProps extends Omit<TextInputProps, "type" | "onChange" | "select"> {
  onChange?: (value: string) => void;
  defaultValue?: string;
  dataTestId?: string;
  min?: string;
  max?: string;
  language?: string;
}

// eslint-disable-next-line complexity
const DatePickerInput = (props: DateInputProps): ReactElement => {
  const { value, defaultValue, dataTestId, invalid, onChange, language, id } = props;
  const elementId = useId(id);
  const [localValue, setLocalValue] = useState<string | Date>(defaultValue ? new Date(defaultValue) : "");
  const datePickerRef = useRef() as MutableRefObject<DatePickerRef>;
  const translations =
    language === "sv" ? trls.sv.datePickerInput : language === "en" ? trls.en.datePickerInput : trls.fi.datePickerInput;

  useEffect(() => {
    setLocalValue(value === null ? "" : value + "");
    datePickerRef?.current?.setValue(value);
  }, [value]);

  const handleChange = (date: string) => {
    if (!onChange) return;
    if (isValidDate(date) || date === "") {
      onChange(date);
    }
    if (date === undefined) {
      onChange("");
    }
  };

  return (
    <DatePicker
      className="kds-mb-0"
      id={elementId}
      testId={dataTestId}
      invalid={invalid}
      errorText={null}
      defaultValue={localValue}
      onChange={(date) => {
        handleChange(date);
      }}
      ref={datePickerRef}
      localization={{
        locale: language === "sv" ? sv : language === "en" ? en : fi,
        ariaLabelWeekNumber: translations.weekNumber,
        labelMonthDropdown: () => translations.monthDropdown,
        labelYearDropdown: () => translations.yearDropdown,
        labelNext: () => translations.nextMonth,
        labelPrevious: () => translations.previousMonth,
        labelToggleCalendar: translations.toggleCalendar,
        labelCloseCalendar: translations.closeCalendar,
        labelSelected: translations.selected,
        labelGoToCurrentDay: translations.goToCurrentDay,
      }}
      placeholder={translations.placeholder}
    />
  );
};

export default DatePickerInput;
